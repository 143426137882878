import {
    handleQueryResolve,
} from '../utils';
// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function (PatientID) {
// FIXME - need to set this up to work with the query for the blister pack 
    if (PatientID) {

        let attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID // to support like
        }];
        let where = ['dos.PatientID = @PatientID'];

    return this.query(`
        SELECT dos.RxNumber
            ,pat.FirstName
            ,pat.LastName
            ,dos.PatientID
            ,dos.Brk
            ,dos.Noon
            ,dos.Supp
            ,dos.Bed
            ,dos.IsInactive
            ,dos.ModifiedOn
            ,dos.Modification
            ,rx.DIN
            ,rx.QtyDispense
            ,rx.SIGFull
            ,rx.RxDate
            ,drug.GenericDrug
            ,drug.GenericName
            ,drug.Strength
            ,drug.BrandName
            ,(drug.Color + ' ' + drug.Shape + ' ' + drug.Form) as description
        FROM [Fillware].[dbo].[DosR] as dos
        INNER JOIN Fillware.dbo.Rx as rx ON dos.RxNumber = rx.RXNumber
        INNER JOIN Fillware.dbo.DrugRoot as drug on rx.DIN = drug.DIN
        INNER JOIN Fillware.dbo.Patient as pat on dos.PatientID = pat.PatientID
        WHERE dos.IsInactive = 0 AND ${where}
    `, attrs).then(handleQueryResolve);
     }
}